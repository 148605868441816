import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View, StyleSheet, Link, Image, Font } from '@react-pdf/renderer';
import { CONTACT_EMAIL } from '../../constants';
import Inter400 from '../../assets/fonts/inter/Inter-Regular.ttf';
import Inter700 from '../../assets/fonts/inter/Inter-Bold.ttf';

Font.register({
  family: 'Inter',
  fonts: [{ src: Inter400 }, { src: Inter700, fontWeight: 700 }],
});
const colors = {
  white: '#ffffff',
  lightGray: '#F5F7F8',
  textColorPrimary: '#32373B',
  textColorSecondary: '#798087',
};
const dashbouquetUrl = 'https://dashbouquet.com/';
const clutchUrl = 'https://clutch.co/profile/dashbouquet-development#summary';
const rateOfTeamMembers = {
  'Frontend Engineer': 43,
  'Backend Engineer': 43,
  Designer: 38,
  'Project Manager': 33,
  'Quality Assurance Engineer': 33,
};
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '90px',
    paddingBottom: '105px',
    maxWidth: '1240px',
    position: 'relative',
    zIndex: 1,
  },
  container: {
    fontFamily: 'Inter',
    color: colors.textColorPrimary,
  },

  mainHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 50px',
    backgroundColor: colors.white,
  },

  summaryItem: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: '20px',
    padding: '30px',
    zIndex: 1,
  },
  reportItemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  link: {
    fontSize: '26px',
    textDecoration: 'underline',
    fontWeight: '700',
  },
  image: {
    width: '187px',
    height: '80px',
  },
  date: {
    fontWeight: '400',
    fontSize: '26px',
    opacity: '0.7',
    color: colors.textColorPrimary,
  },
  mainTitle: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '150%',
    color: colors.textColorPrimary,
  },
  content: {
    zIndex: 3,
    padding: 50,
  },
  headerDescription: {
    position: 'relative',
    padding: ' 0 50px',
    marginTop: '80px',
    backgroundColor: colors.white,
  },
  divider: {
    width: '100%',
    bottom: '-50px',
    zIndex: 100,
    backgroundColor: 'transparent',
    objectFit: 'contain',
  },
  card: {
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: colors.textColorPrimary,
    borderRadius: '30px',
    maxWidth: '520px',
    width: '100%',
    height: '221px',
    zIndex: 7,
  },
  cardTextBlock: {
    zIndex: '10',
    backgroundColor: 'transparent',
    flexDirection: 'column',
    color: colors.white,
    padding: '50px',
  },
  cardText: {
    marginTop: '20px',
    color: colors.white,
    fontSize: '48px',
    fontWeight: '700',
  },
  text: {
    fontSize: '26px',
    fontWeight: '400',
    color: colors.textColorPrimary,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '700',
    fontSize: '26px',
    lineHeight: '150%',
    marginTop: '10px',
    textAlign: 'left',
  },
  tableHeaderWrapper: {
    backgroundColor: colors.lightGray,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    flexDirection: 'column',
    padding: '10px',
  },
  tableHeader: {
    backgroundColor: colors.textColorPrimary,
    borderRadius: '20px',
    flexDirection: 'row',
    color: colors.white,
    fontSize: '26px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 30px',
    textAlign: 'left',
  },
  tableWrapper: {
    backgroundColor: colors.lightGray,
    borderRadius: '30px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    flexDirection: 'column',
    padding: '10px',
  },
});

const MVPCalculatorResultPDFDocument = ({
  totalAmount,
  reportDataBySteps,
  projectIdea,
  projectEstimation,
  estimationByMembers,
  pdfImgs,
  date,
  technicalSolutions,
}) => (
  <Document>
    <Page size="C2" style={styles.page}>
      <View style={styles.container}>
        <View>
          <View style={styles.mainHeader}>
            <Image style={styles.image} src={pdfImgs.mvpPdfLogo} />
            <Text style={styles.date}>{`${date.getDate()} ${new Intl.DateTimeFormat('en-US', {
              month: 'long',
            }).format(date)} ${date.getFullYear()}`}</Text>
          </View>
          <View style={styles.headerDescription}>
            <Text style={[styles.mainTitle, { marginBottom: '10px' }]}>
              Your project estimate & technical solution
            </Text>
            <Text style={styles.text}>by Dashbouquet Development</Text>
            <Image style={{ width: '100px', margin: '40px 0px' }} src={pdfImgs.mvpStarsImg} />

            <View style={{ marginBottom: '16px' }}>
              <Text style={styles.text}>
                The estimate is generated automatically. {'\n'} Please, contact us for an accurate
                calculation.
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.content}>
          <View>
            <Text style={[styles.mainTitle, { marginBottom: '30px', marginTop: '80px' }]}>
              Your ballpark project estimate
            </Text>
            <View style={[styles.reportItemHeader]}>
              <View style={styles.card}>
                <View style={styles.cardTextBlock}>
                  <Text style={[styles.text, { color: colors.white, opacity: '0.7' }]}>
                    Total development cost
                  </Text>
                  <Text style={styles.cardText}>{`$${totalAmount}`}</Text>
                </View>

                <Image style={{ objectFit: 'contain', zIndex: 7 }} src={pdfImgs.mvpWalletImg} />
              </View>
              <View style={styles.card}>
                <View style={styles.cardTextBlock}>
                  <Text style={[styles.text, { color: colors.white, opacity: '0.7' }]}>
                    Project duration
                  </Text>
                  <Text style={styles.cardText}>{`${projectEstimation} weeks`}</Text>
                </View>
                <Image style={{ objectFit: 'contain', zIndex: 7 }} src={pdfImgs.mvpClockImg} />
              </View>
            </View>
          </View>

          <View style={{ marginTop: '80px' }}>
            <Text style={[styles.mainTitle, { marginBottom: '10px' }]}>Project idea</Text>
            <Text style={[styles.text, { color: colors.textColorSecondary, marginBottom: '30px' }]}>
              {projectIdea}
            </Text>
          </View>

          <View style={{ marginTop: '80px' }}>
            <View minPresenceAhead={200}>
              <Text style={[styles.mainTitle, { marginBottom: '10px' }]}>Summary</Text>
              <Text
                style={[styles.text, { color: colors.textColorSecondary, marginBottom: '30px' }]}
              >
                Check out the details on project estimation according to your answers.
              </Text>
            </View>
            <View>
              <View fixed style={[styles.tableHeaderWrapper]}>
                <View style={styles.tableHeader}>
                  <Text style={{ flexBasis: '40%' }}>Your answer</Text>
                  <Text>Time</Text>
                  <Text>Duration</Text>
                  <Text>Cost</Text>
                </View>
              </View>

              <View style={styles.tableWrapper}>
                {reportDataBySteps?.map((el, i) => (
                  <View
                    wrap={false}
                    key={el.value}
                    style={
                      i % 2 === 0
                        ? [
                            styles.summaryItem,
                            {
                              backgroundColor: colors.white,
                            },
                          ]
                        : [
                            styles.summaryItem,
                            {
                              backgroundColor: 'transparent',
                            },
                          ]
                    }
                  >
                    <View>
                      <Text
                        style={{
                          color: colors.textColorSecondary,
                          fontWeight: '400',
                          fontSize: '26px',
                        }}
                      >
                        {el.title}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <View style={{ flexDirection: 'column', flexBasis: '40%' }}>
                        {el.itemsTitles.map((itemTitle) => (
                          <Text key={itemTitle}>{itemTitle}</Text>
                        ))}
                      </View>
                      <Text>{`${el.timeEstimation} h`}</Text>
                      <Text>{`${Math.ceil(
                        Math.max(...el.costByTeamMember.map((item) => item.estimation)) / 8
                      )} days`}</Text>
                      <Text>{`$${el.stepCost}`}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <Text style={[styles.text, { color: colors.textColorSecondary, marginTop: '30px' }]}>
              The estimates are approximate, but give you good insights on the budget and timeline
              to build your product.
            </Text>
            <Text style={[styles.text, { color: colors.textColorSecondary, marginTop: '30px' }]}>
              Note that AI technologies can cost significantly more on a case-by-case basis. Contact
              us at{' '}
              <Text style={[styles.link, { fontWeight: '700', opacity: '1' }]}>
                contact@dashbouquet.com
                <Link src={`${CONTACT_EMAIL}`} />
              </Text>{' '}
              for the exact cost.
            </Text>
          </View>

          <View style={{ marginTop: '80px' }}>
            <View minPresenceAhead={200}>
              {' '}
              <Text style={[styles.mainTitle, { marginBottom: '40px' }]}>Components selection</Text>
            </View>
            <View>
              <View fixed style={styles.tableHeaderWrapper}>
                <View style={styles.tableHeader}>
                  <Text style={{ flexBasis: '50%' }}>Component</Text>
                  <Text style={{ flexBasis: '50%' }}>Technical solution</Text>
                </View>
              </View>

              <View style={styles.tableWrapper}>
                {Object.entries(technicalSolutions)?.map((el, i) => (
                  <View
                    wrap={false}
                    key={i}
                    style={
                      i % 2 === 0
                        ? [
                            styles.summaryItem,
                            {
                              backgroundColor: colors.white,
                            },
                          ]
                        : [
                            styles.summaryItem,
                            {
                              backgroundColor: 'transparent',
                            },
                          ]
                    }
                  >
                    <View style={styles.row}>
                      <Text style={{ flexBasis: '50%' }}>{`${el[0]}`}</Text>
                      <Text style={{ flexBasis: '50%' }}>
                        {[...new Set(el[1].map((k) => k.solution))].map((item, i) => (
                          <Text key={i}>
                            {`${item}`}
                            {'\n'}
                          </Text>
                        ))}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={{ marginTop: '80px' }}>
            <View minPresenceAhead={200}>
              <Text style={[styles.mainTitle, { marginBottom: '40px' }]}>Your Team</Text>
            </View>
            <View>
              <View fixed style={[styles.tableHeaderWrapper]}>
                <View style={[styles.tableHeader]}>
                  <Text style={{ flexBasis: '50%' }}>Specialist</Text>
                  <Text style={{ flexBasis: '50%' }}>Hours, in total</Text>
                </View>
              </View>

              <View style={styles.tableWrapper}>
                {estimationByMembers.map(({ id, title, estimation }, i) => (
                  <View
                    wrap={false}
                    key={id}
                    style={
                      i % 2 === 0
                        ? [
                            styles.summaryItem,
                            {
                              backgroundColor: colors.white,
                            },
                          ]
                        : [
                            styles.summaryItem,
                            {
                              backgroundColor: 'transparent',
                            },
                          ]
                    }
                  >
                    <View style={styles.row}>
                      <Text style={{ flexBasis: '50%' }}>{title}</Text>
                      <Text style={{ zIndex: 1, flexBasis: '50%' }}>{`${estimation} hours`}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={{ margin: '80px 0px' }}>
            <View minPresenceAhead={120}>
              <Text style={[styles.mainTitle, { marginBottom: '40px' }]}>
                Suggested cooperation model
              </Text>
              <Text
                style={[styles.text, { color: colors.textColorSecondary, marginBottom: '40px' }]}
              >
                Time & Material
              </Text>
            </View>
            <View>
              <View fixed style={styles.tableHeaderWrapper}>
                <View style={styles.tableHeader}>
                  <Text style={{ flexBasis: '50%' }}>Specialist</Text>
                  <Text style={{ flexBasis: '50%' }}>Hourly rate</Text>
                </View>
              </View>
              <View style={styles.tableWrapper}>
                {Object.entries(rateOfTeamMembers).map((el, i) => (
                  <View
                    wrap={false}
                    key={i}
                    style={
                      i % 2 === 0
                        ? [
                            styles.summaryItem,
                            {
                              backgroundColor: colors.white,
                            },
                          ]
                        : [
                            styles.summaryItem,
                            {
                              backgroundColor: 'transparent',
                            },
                          ]
                    }
                  >
                    <View style={styles.row}>
                      <Text style={{ flexBasis: '50%' }}>{`${el[0]}`}</Text>
                      <Text style={{ flexBasis: '50%' }}>{`$${el[1]}`}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>

        <View
          wrap={false}
          style={[
            styles.text,
            { padding: '0 50px', opacity: '0.7', backgroundColor: colors.white },
          ]}
        >
          <Text style={{ fontSize: '38px', fontWeight: '700', opacity: '1' }}>Contact us</Text>
          <Text style={{ marginTop: '40px' }}>
            Have any questions or need consultation on tech? Сontact us to get an accurate estimate
            tailored to your exact requirements.
          </Text>
          <Image style={{ width: '100px', margin: '40px 0px' }} src={pdfImgs.mvpStarsImg} />
          <Text
            href={`mailto:${CONTACT_EMAIL}`}
            style={[styles.link, { marginBottom: '40px', opacity: '1' }]}
          >
            {CONTACT_EMAIL}
          </Text>
          <Text style={{ margiinBottom: '20px' }}>+1  646  934  8662 (US)</Text>
          <Text
            href="https://maps.google.com/?q=Estonia, Tallinn city, Harju county, 10117, Narva mnt 7-353"
            style={{ margiinBottom: '20px' }}
          >
            Estonia, Tallinn city Harju county, 10117, Narva mnt 7-353
          </Text>
          <Image style={{ width: '100px', margin: '40px 0px' }} src={pdfImgs.mvpStarsImg} />
          <Text style={[{ margin: '40px 0px' }]}>
            Dashbouquet is a custom software development company, helping businesses address their
            needs through tech since 2014.
          </Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text href={dashbouquetUrl} style={[styles.link, { fontSize: '26px', opacity: '1' }]}>
              Dashbouquet Development
            </Text>
            <Text>
              See our customer reviews on{' '}
              <Text href={clutchUrl} style={[styles.link, { fontWeight: '700', opacity: '1' }]}>
                Clutch
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

MVPCalculatorResultPDFDocument.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  reportDataBySteps: PropTypes.arrayOf(PropTypes.shape).isRequired,
  projectEstimation: PropTypes.number.isRequired,
  estimationByMembers: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default MVPCalculatorResultPDFDocument;

import AgrotechIcon from '../../assets/icons/MVPCalculator/steps/platform/agrotech.svg';
import CryptoIcon from '../../assets/icons/MVPCalculator/steps/platform/crypto.svg';
import ECommerceIcon from '../../assets/icons/MVPCalculator/steps/platform/eCommerce.svg';
import ELearningIcon from '../../assets/icons/MVPCalculator/steps/platform/eLearning.svg';
import FintechIcon from '../../assets/icons/MVPCalculator/steps/platform/fintech.svg';
import HealthcareIcon from '../../assets/icons/MVPCalculator/steps/platform/healthcare.svg';
import HRSolutionsIcon from '../../assets/icons/MVPCalculator/steps/platform/HRSolutions.svg';
import LogisticsIcon from '../../assets/icons/MVPCalculator/steps/platform/logistics.svg';
import OtherIcon from '../../assets/icons/MVPCalculator/steps/platform/other.svg';
import RealEstateIcon from '../../assets/icons/MVPCalculator/steps/platform/realEstate.svg';
import TravelAndHospitalityIcon from '../../assets/icons/MVPCalculator/steps/platform/travelAndHospitality.svg';
import IOSIcon from '../../assets/icons/MVPCalculator/steps/platform/iOS.svg';
import WebIcon from '../../assets/icons/MVPCalculator/steps/platform/web.svg';
import AndroidIcon from '../../assets/icons/MVPCalculator/steps/platform/android.svg';
import MobileIcon from '../../assets/icons/MVPCalculator/steps/platform/mobile.svg';

import AITechnologiesIcon from '../../assets/icons/MVPCalculator/steps/parameter/aiTechnologies.svg';
import BlockchainIcon from '../../assets/icons/MVPCalculator/steps/parameter/blockchain.svg';
import CalendarIcon from '../../assets/icons/MVPCalculator/steps/parameter/calendar.svg';
import CMSIcon from '../../assets/icons/MVPCalculator/steps/parameter/CMS.svg';
import DatabaseIcon from '../../assets/icons/MVPCalculator/steps/parameter/database.svg';
import DiagramsIcon from '../../assets/icons/MVPCalculator/steps/parameter/diagrams.svg';
import LocalizationNewIcon from '../../assets/icons/MVPCalculator/steps/parameter/localization.svg';
import LocationsIcon from '../../assets/icons/MVPCalculator/steps/parameter/locations.svg';
import MediaNewIcon from '../../assets/icons/MVPCalculator/steps/parameter/media.svg';
import MonetizationIcon from '../../assets/icons/MVPCalculator/steps/parameter/monetization.svg';
import NotificationsIcon from '../../assets/icons/MVPCalculator/steps/parameter/notifications.svg';
import SecurityIcon from '../../assets/icons/MVPCalculator/steps/parameter/security.svg';
import SocialFeaturesIcon from '../../assets/icons/MVPCalculator/steps/parameter/socialFeatures.svg';
import UserIcon from '../../assets/icons/MVPCalculator/steps/parameter/user.svg';
import UserGeneratedContentIcon from '../../assets/icons/MVPCalculator/steps/parameter/userGeneratedContent.svg';

import EmailAndPasswordIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/emailAndPassword.svg';
import EmailInvitationsIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/emailInvitations.svg';
import TwitterIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/twitter.svg';
import DataAndPrivacyIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/dataAndPrivacy.svg';
import AccountRecoveryIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/accountRecovery.svg';
import ProfileIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/profile.svg';
import ManagementIcon from '../../assets/icons/MVPCalculator/steps/usersAndAccounts/management.svg';

import ReportsIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/reports.svg';
import PrivacyIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/privacy.svg';
import MediaFilesIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/mediaFiles.svg';
import ContentEditingIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/contentEditing.svg';
import LikesIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/likes.svg';
import TagsIcon from '../../assets/icons/MVPCalculator/steps/generatedContent/tags.svg';

import AnalyticsIcon from '../../assets/icons/MVPCalculator/steps/CMS/analytics.svg';
import CMSIntegrationIcon from '../../assets/icons/MVPCalculator/steps/CMS/CMSIntegration.svg';
import CustomCMSIcon from '../../assets/icons/MVPCalculator/steps/CMS/customCMS.svg';

import ThirdPartyAPIIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/thirdPartyAPI.svg';
import ThirdPartyDataIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/thirdPartyData.svg';
import APIIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/API.svg';
import MediaIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/media.svg';
import SyncIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/sync.svg';
import TextIcon from '../../assets/icons/MVPCalculator/steps/databaseAndAPI/text.svg';

import BookingIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/booking.svg';
import CalendarsIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/calendars.svg';
import EventListingsIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/eventListings.svg';
import MenuOrderingIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/menuOrdering.svg';
import ReservationsIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/reservations.svg';
import TicketingIcon from '../../assets/icons/MVPCalculator/steps/calendarsAndBooking/ticketing.svg';

import LocationsThirdPartyDataIcon from '../../assets/icons/MVPCalculator/steps/locations/thirdPartyData.svg';
import GeofencingIcon from '../../assets/icons/MVPCalculator/steps/locations/geofencing.svg';
import MapsIcon from '../../assets/icons/MVPCalculator/steps/locations/maps.svg';
import MarkersIcon from '../../assets/icons/MVPCalculator/steps/locations/markers.svg';
import NavigationIcon from '../../assets/icons/MVPCalculator/steps/locations/navigation.svg';

import DataSearchIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/dataSearch.svg';
import PlaybackIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/playback.svg';
import ProcessingIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/processing.svg';
import UploadIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/upload.svg';
import DownloadMediaIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/downloadMedia.svg';
import PlaylistIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/playlist.svg';
import MediaFilesEditingIcon from '../../assets/icons/MVPCalculator/steps/mediaAndSearch/mediaFilesEditing.svg';

import ChatIcon from '../../assets/icons/MVPCalculator/steps/messaging/chat.svg';
import EmailAndSMSIcon from '../../assets/icons/MVPCalculator/steps/messaging/emailAndSMS.svg';
import ForumsAndCommentsIcon from '../../assets/icons/MVPCalculator/steps/messaging/forumsAndComments.svg';
import PushNotificationsIcon from '../../assets/icons/MVPCalculator/steps/messaging/pushNotifications.svg';
import SocialSharingIcon from '../../assets/icons/MVPCalculator/steps/messaging/socialSharing.svg';

import {
  AnimatedGraphicsIcon,
  CustomChartsIcon,
  CustomDiagramsIcon,
  DashboardIcon,
  TaskListsIcon,
} from '../../assets/icons/MVPCalculator/steps/diagramAndCharts';

import {
  CommissionsIcon,
  PaymentProcessingIcon,
  PayoutsWithCommisionsIcon,
  PurchasesIcon,
  SubscriptionPlansIcon,
} from '../../assets/icons/MVPCalculator/steps/monetization';

import {
  ContentModerationIcon,
  CRMIcon,
  ReportingIcon,
  UsersManagementIcon,
} from '../../assets/icons/MVPCalculator/steps/CMS';

import {
  MultipleLanguagesIcon,
  rtlSupportIcon,
} from '../../assets/icons/MVPCalculator/steps/localization';

import {
  ComplianceIcon,
  EncryptionIcon,
  KeyIcon,
  SSLCertificatesIcon,
} from '../../assets/icons/MVPCalculator/steps/security';

import {
  ARIcon,
  DataProcessingIcon,
  FacialRecognitionIcon,
  ImageClassificationIcon,
  ImageGenerationIcon,
  ImageRecognitionIcon,
  MultipleLanguagesSupportIcon,
  NaturalLanguageQueryIcon,
  OCRIcon,
  PredictiveAnalyticsIcon,
  TextClassificationIcon,
  TextGenerationIcon,
} from '../../assets/icons/MVPCalculator/steps/ai';

import {
  CryptoWalletIcon,
  DeFiIcon,
  MultipleNetworksIcon,
  NFTIcon,
} from '../../assets/icons/MVPCalculator/steps/blockchain';

export const COST_RATE_BY_TEAM_MEMBERS = {
  frontend: 43,
  backend: 43,
  designer: 38,
  PM: 33,
  QA: 33,
};
export const requiredPositions = ['frontend', 'backend', 'designer'];
export const optionalPositions = ['QA', 'PM'];

export const MVP_CALCULATOR_REQUIRED_STEPS = {
  DOMAIN: 'domain',
  BUSINESS_GOAL: 'businessGoal',
  SPECIFY_DATE: 'specifyDate',
  PLATFORM: 'platform',
  SIZE: 'size',
  CUSTOM_DESIGN: 'customDesign',
  DESIGN_COMPLEXITY: 'designComplexity',
  CLOUD_INFRASTRUCTURE_PROVIDER: 'cloudInfrastructureProvider',
  PARAMETERS_SELECTION: 'parametersSelection',
  PROJECT_IDEA: 'projectIdea',
};
export const MVPCalculatorRequiredSteps = [
  MVP_CALCULATOR_REQUIRED_STEPS.DOMAIN,
  MVP_CALCULATOR_REQUIRED_STEPS.BUSINESS_GOAL,
  MVP_CALCULATOR_REQUIRED_STEPS.SPECIFY_DATE,
  MVP_CALCULATOR_REQUIRED_STEPS.PLATFORM,
  MVP_CALCULATOR_REQUIRED_STEPS.SIZE,
  MVP_CALCULATOR_REQUIRED_STEPS.CUSTOM_DESIGN,
  MVP_CALCULATOR_REQUIRED_STEPS.DESIGN_COMPLEXITY,
  MVP_CALCULATOR_REQUIRED_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER,
  MVP_CALCULATOR_REQUIRED_STEPS.PARAMETERS_SELECTION,
  MVP_CALCULATOR_REQUIRED_STEPS.PROJECT_IDEA,
];
export const PARAMETERS_SELECTION_STEP_INDEX = MVPCalculatorRequiredSteps.findIndex(
  (step) => step === MVP_CALCULATOR_REQUIRED_STEPS.PARAMETERS_SELECTION
);

export const MVP_CALCULATOR_OPTIONAL_STEPS = {
  USERS_AND_ACCOUNTS: 'usersAndAccounts',
  GENERATED_CONTENT: 'generatedContent',
  MEDIA_AND_SEARCH: 'mediaAndSearch',
  CALENDARS_AND_BOOKING: 'calendarsAndBooking',
  DIAGRAM_AND_CHARTS: 'diagramAndCharts',
  LOCATIONS: 'locations',
  SOCIAL_FEATURES_AND_MESSAGING: 'socialFeaturesAndMessaging',
  NOTIFICATIONS: 'notifications',
  DATABASE_AND_API: 'databaseAndAPI',
  MONETIZATION: 'monetization',
  ADMIN_PANEL: 'adminPanel',
  LOCALIZATION: 'localization',
  SECURITY: 'security',
  AI_TECHNOLOGIES: 'aiTechnologies',
  BLOCKCHAIN: 'blockchain',
};
export const MVPCalculatorOptionalSteps = [
  MVP_CALCULATOR_OPTIONAL_STEPS.USERS_AND_ACCOUNTS,
  MVP_CALCULATOR_OPTIONAL_STEPS.GENERATED_CONTENT,
  MVP_CALCULATOR_OPTIONAL_STEPS.MEDIA_AND_SEARCH,
  MVP_CALCULATOR_OPTIONAL_STEPS.CALENDARS_AND_BOOKING,
  MVP_CALCULATOR_OPTIONAL_STEPS.DIAGRAM_AND_CHARTS,
  MVP_CALCULATOR_OPTIONAL_STEPS.LOCATIONS,
  MVP_CALCULATOR_OPTIONAL_STEPS.SOCIAL_FEATURES_AND_MESSAGING,
  MVP_CALCULATOR_OPTIONAL_STEPS.NOTIFICATIONS,
  MVP_CALCULATOR_OPTIONAL_STEPS.DATABASE_AND_API,
  MVP_CALCULATOR_OPTIONAL_STEPS.MONETIZATION,
  MVP_CALCULATOR_OPTIONAL_STEPS.ADMIN_PANEL,
  MVP_CALCULATOR_OPTIONAL_STEPS.LOCALIZATION,
  MVP_CALCULATOR_OPTIONAL_STEPS.SECURITY,
  MVP_CALCULATOR_OPTIONAL_STEPS.AI_TECHNOLOGIES,
  MVP_CALCULATOR_OPTIONAL_STEPS.BLOCKCHAIN,
];

export const MVP_CALCULATOR_STEPS = {
  ...MVP_CALCULATOR_REQUIRED_STEPS,
  ...MVP_CALCULATOR_OPTIONAL_STEPS,
};
export const MVPCalculatorSteps = [...MVPCalculatorRequiredSteps, ...MVPCalculatorOptionalSteps];

/*
  {
    value: string;
    title: string;
    description: string;
    isMultiSelect: boolean;
    canBeSkipped: boolean;
    rateOfTeam: {
      frontend: number;
      backend: number;
      designer: number;
    } | null;
    items: [
      {
        value: string;
        icon: SVG | null;
        title: string;
        cost: {
          'platform:ios': number;
          'platform:web': number;
          'platform:hybrydMobile': number;
        } | number | null;
        
        additionalInfo: string;
        description: string;
      }
    ]
  }
*/

const MVPCalculatorStepsData = [
  {
    value: MVP_CALCULATOR_STEPS.DOMAIN,
    title: 'Specify your domain ',
    description: ['Select as many options as you want'],
    columns: 5,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:healthcare`,
        icon: HealthcareIcon,
        title: 'Healthcare',

        estimation: null,
        description: '',
        additionalInfo:
          'Software development for medical devices, patient portals, telemedicine apps, EHR/EMR, mobile medical app development.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:fintech`,
        icon: FintechIcon,
        title: 'Fintech',

        estimation: null,
        description: '',
        additionalInfo:
          'Fintech apps, banking & payment solutions, trading platforms, lending, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:eCommerce`,
        icon: ECommerceIcon,
        title: 'eCommerce',

        estimation: null,
        description: '',
        additionalInfo:
          'Online stores, B2B/B2C marketplaces, mobile commerce platforms, CRM systems, auction platforms',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:agrotech`,
        icon: AgrotechIcon,
        title: 'Agrotech',

        estimation: null,
        description: '',
        additionalInfo:
          'Farm management apps, GIS agrotech, IoT agriculture sensors, farm accounting software, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:hrSolutions`,
        icon: HRSolutionsIcon,
        title: 'HR solutions',

        estimation: null,
        description: '',
        additionalInfo:
          'HRIS development for personnel tracking, payroll, benefits, recruiting, onboarding, career planning, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:travelHospitality`,
        icon: TravelAndHospitalityIcon,
        title: 'Travel & Hospitality',

        estimation: null,
        description: '',
        additionalInfo:
          'Travel portals, hotel management software, mobile apps for hospitality, restaurant management system, booking systems, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:logistics`,
        icon: LogisticsIcon,
        title: 'Logistics',

        estimation: null,
        description: '',
        additionalInfo:
          'Logistics & transportation management, warehouse management, asset tracking apps, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:eLearning`,
        icon: ELearningIcon,
        title: 'eLearning',

        estimation: null,
        description: '',
        additionalInfo:
          'eLearning apps,MS/LCMS, education portals, virtual classrooms, school management.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:realEstate`,
        icon: RealEstateIcon,
        title: 'Real Estate',

        estimation: null,
        description: '',
        additionalInfo: 'Commercial & rental property management, CRM/ERP, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:crypto`,
        icon: CryptoIcon,
        title: 'Crypto',

        estimation: null,
        description: '',
        additionalInfo:
          'DApps development, cryptocurrency exchanges, smart contracts, crypto mining services, NFT platforms, etc.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DOMAIN}:other`,
        icon: OtherIcon,
        title: 'Other',

        estimation: null,
        description: '',
        additionalInfo:
          'Haven’t found your domain? Select the check box to make a custom solution tailored to your business.',
      },
    ],
  },

  {
    value: MVP_CALCULATOR_STEPS.BUSINESS_GOAL,
    title: 'What’s your business goal?',
    description: [''],
    columns: 4,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.BUSINESS_GOAL}:wandering`,
        icon: null,
        title: 'Just wandering around',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BUSINESS_GOAL}:obtainFunds`,
        icon: null,
        title: 'Obtain funds, pitch a concept or validate the idea',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BUSINESS_GOAL}:consideringPrices`,
        icon: null,
        title: 'Considering prices on market',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BUSINESS_GOAL}:buildingProject`,
        icon: null,
        title: 'I want to start building a project',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.SPECIFY_DATE,
    title: 'Specify the date to start/release your project you’re sticking to',
    description: [''],
    columns: 4,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.SPECIFY_DATE}:month`,
        icon: null,
        title: 'in 1 month',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SPECIFY_DATE}:2-3`,
        icon: null,
        title: '2 - 3 months',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SPECIFY_DATE}:4-6`,
        icon: null,
        title: '4 - 6 months',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SPECIFY_DATE}above6`,
        icon: null,
        title: 'more than 6 months',

        estimation: null,
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.PLATFORM,
    title: 'Choose your platform',
    description: [],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0.5,
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.PLATFORM}:web`,
        icon: WebIcon,
        title: 'Web',
        technicalSolution: [
          {
            component: 'Frontend Library',
            solution: 'React',
          },
        ],
        estimation: 25,
        description: '',
        additionalInfo: 'Create rich web app using Angular, React or similar frameworks',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.PLATFORM}:ios`,
        icon: IOSIcon,
        title: 'Native iOS',
        technicalSolution: [
          {
            component: 'iOS',
            solution: 'Swift',
          },
        ],
        estimation: 25,
        description: '',
        additionalInfo:
          'Create native apps for iPhone, iPad, Apple Watch, Apple TV made with Swift.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.PLATFORM}:android`,
        icon: AndroidIcon,
        title: 'Native Android',
        technicalSolution: [
          {
            component: 'Android',
            solution: 'Java, Kotlin',
          },
        ],
        estimation: 25,
        description: '',
        additionalInfo:
          'Create native apps for all types of Android-based devices made with Kotlin or Java.',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.PLATFORM}:hybrydMobile`,
        icon: MobileIcon,
        title: 'Hybrid mobile',
        technicalSolution: [
          {
            component: 'Hybrid Framework',
            solution: 'React Native',
          },
        ],
        estimation: 35,
        description: '',
        additionalInfo:
          'Create cross-platform apps with a single codebase for both iOS and Android users.',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.SIZE,
    title: 'How big is your app?',
    description: ['Select how many screens you want your app to have'],
    columns: 4,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 1,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.SIZE}:xs`,
        icon: null,
        title: '1-3',

        estimation: {
          'platform:ios': 24,
          'platform:android': 24,
          'platform:hybrydMobile': 39,
          'platform:web': 24,
        },
        additionalInfo: '',
        description: 'screens',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SIZE}:s`,
        icon: null,
        title: '4-8',

        estimation: {
          'platform:ios': 47,
          'platform:android': 47,
          'platform:hybrydMobile': 74,
          'platform:web': 47,
        },

        additionalInfo: '',
        description: 'screens',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SIZE}:m`,
        icon: null,
        title: '9-15',

        estimation: {
          'platform:ios': 85,
          'platform:android': 85,
          'platform:hybrydMobile': 136,
          'platform:web': 82,
        },
        additionalInfo: '',
        description: 'screens',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SIZE}:l`,
        icon: null,
        title: 'Over 15',

        estimation: {
          'platform:ios': 153,
          'platform:android': 154,
          'platform:hybrydMobile': 244,
          'platform:web': 157,
        },
        additionalInfo: '',
        description: 'screens',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.CUSTOM_DESIGN,
    title: 'Do you need custom designs for your app?',
    description: [],
    columns: 2,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.CUSTOM_DESIGN}:true`,
        icon: null,
        title: 'Yes',

        estimation: null,
        additionalInfo: '',
        description: 'I need beautiful design for my app',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CUSTOM_DESIGN}:false`,
        icon: null,
        title: 'No',

        estimation: null,
        additionalInfo: '',
        description: `I don't care about the designs or have my own`,
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY,
    title: 'How complex should the UI / UX be?',
    description: [
      'Low is better for strict b2b apps while high is better for sparkle b2c entertainment apps.',
    ],
    columns: 3,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 1,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY}:low`,
        icon: null,
        title: 'Low',

        estimation: {
          'platform:ios': 21,
          'platform:android': 20,
          'platform:hybrydMobile': 30,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: 'Static lists, simple forms',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY}:medium`,
        icon: null,
        title: 'Medium',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: 'Dynamic lists, animated transitions',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY}:high`,
        icon: null,
        title: 'High',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 71,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: 'Unique requirements, highly polished UX',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER,
    title: 'Cloud infrastructure provider',
    description: [
      'Specify a cloud platform if you have any preferences or let us choose the appropriate one',
    ],
    columns: 3,
    isMultiSelect: false,
    canBeSkipped: false,
    rateOfTeam: {
      frontend: 0,
      backend: 1,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:aws`,
        icon: null,
        title: 'AWS',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:digitalOcean`,
        icon: null,
        title: 'Digital Ocean',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:msAzure`,
        icon: null,
        title: 'Microsoft Azure',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:googleCloudPlatform`,
        icon: null,
        title: 'Google Cloud Platform',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:customCloudPlatform`,
        icon: null,
        title: 'Custom cloud platform',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER}:dbChoose`,
        icon: null,
        title: 'Let Dashbouquet choose',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.PARAMETERS_SELECTION,
    title: 'What features should your app have?',
    description: ['Select as many options as you want'],
    columns: 5,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [
      {
        value: MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS, // `usersAndAccounts`,
        icon: UserIcon,
        title: 'Users & Accounts',

        additionalInfo:
          'Email or social sign up/login,profile management, account settings & activity etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.GENERATED_CONTENT, // `generatedContent`,
        icon: UserGeneratedContentIcon,
        title: 'User generated content',

        additionalInfo:
          'Ability to upload, share & interact with users’ content, editing, reports, tags, etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH, // `mediaAndSearch`,
        icon: MediaNewIcon,
        title: 'Media, files & search',

        additionalInfo: 'Audio, video, photo, text files uploading and processing, data search.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING, // `calendarsAndBooking`,
        icon: CalendarIcon,
        title: 'Calendars, booking & scheduling ',

        additionalInfo: 'Calendar sync, booking page, event listings, time zones, etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS, // `diagramAndCharts`,
        icon: DiagramsIcon,
        title: 'Diagrams, charts & dashboards',

        additionalInfo: 'Task lists, charts, diagrams, etc',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.LOCATIONS, // `locations`,
        icon: LocationsIcon,
        title: 'Locations',

        additionalInfo: 'Maps, navigation, geofencing, 3rd party location data, etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.SOCIAL_FEATURES_AND_MESSAGING, // `socialFeaturesAndMessaging`,
        icon: SocialFeaturesIcon,
        title: 'Social features & messaging',

        additionalInfo: 'Chat, forums, comments, etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.NOTIFICATIONS, // `notifications`,
        icon: NotificationsIcon,
        title: `Notifications`,

        additionalInfo: `Push notifications, SMS, E-mails`,
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.DATABASE_AND_API, // `databaseAndAPI`,
        icon: DatabaseIcon,
        title: `Database, 3rd party APIs & data`,

        additionalInfo: `Storing of different files, real time synchronization, 3rd party APIs integration, etc.`,
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.MONETIZATION, // `monetization`,
        icon: MonetizationIcon,
        title: 'Monetization, advertisement & payment processing',

        additionalInfo:
          'In-app purchases, subscriptions, fees, refunds, advertisement platforms integration, payment gateways integration, e.g. Braintree, PayPal',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.ADMIN_PANEL, // `adminPanel`,
        icon: CMSIcon,
        title: 'Admin panel, CMS, reporting & analytics',

        additionalInfo: 'Content and user management, analytics & reporting, etc.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.LOCALIZATION, // `localization`,
        icon: LocalizationNewIcon,
        title: 'Localization',

        additionalInfo:
          'Language and regionalisms, cultural & transactional elements, design, flow and navigation.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.SECURITY, // `security`,
        icon: SecurityIcon,
        title: 'Security & compliance',

        additionalInfo:
          'SSL certificates, two-factor authentication,GDPR, HIPAA, PCI DSS compliance.',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES, // `aiTechnologies`,
        icon: AITechnologiesIcon,
        title: 'AI technologies',

        additionalInfo:
          'NLP capabilities, such as text & image generation, real-time data processing, etc. Image and video recognition and analysis',
        description: '',
      },
      {
        value: MVP_CALCULATOR_STEPS.BLOCKCHAIN, // `blockchain`,
        icon: BlockchainIcon,
        title: 'Blockchain & NFT',

        additionalInfo:
          'Crypto wallet, buying or selling multiple cryptocurrencies and NFTs, NFTs minting, transaction history, etc.',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.PROJECT_IDEA,
    title: 'Please describe your project idea',
    description: [''],
    rateOfTeam: {
      frontend: 0,
      backend: 0,
      designer: 0,
    },
    items: [],
  },
  {
    value: MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS,
    title: 'Users & Accounts',
    description: [
      'Add more features to make the registration and authorization process smooth for your customers',
    ],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.6,
      backend: 0.4,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:emailAndPassword`,
        icon: EmailAndPasswordIcon,
        title: 'E-mail/Password\nSign up/Sign in',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
          {
            component: 'AWS',
            solution: 'AWS Cognito',
          },
          {
            component: 'GCP',
            solution: 'Google Identity Platform',
          },
          {
            component: 'Azure',
            solution: 'Azure Active Directory B2C',
          },
        ],
        estimation: {
          'platform:ios': 28,
          'platform:android': 29,
          'platform:hybrydMobile': 41,
          'platform:web': 29,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:socialLogin`,
        icon: TwitterIcon,
        title: 'Social login',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: 'Sign up/Sign in via Facebook, Twitter, In, Git, etc.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:emailInvitations`,
        icon: EmailInvitationsIcon,
        title: 'E-mail invitations',
        technicalSolution: [
          {
            component: 'Email service',
            solution: 'SendGrid/MailGun',
          },
          {
            component: 'AWS',
            solution: 'SES',
          },
          {
            component: 'GCP',
            solution: 'SendGrid/MailGun',
          },
          {
            component: 'Azure',
            solution: 'Azure Communication Services',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 29,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:tenantUserManagement`,
        icon: ManagementIcon,
        title: 'Tenant user management',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
          {
            component: 'AWS',
            solution: 'AWS Cognito',
          },
          {
            component: 'GCP',
            solution: 'Google Identity Platform',
          },
          {
            component: 'Azure',
            solution: 'Azure Active Directory B2C',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo:
          'Multi-Tenant provides an easy management of multiple clients on the same instance.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:profileManagement`,
        icon: ProfileIcon,
        title: 'Profile management',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
          {
            component: 'AWS',
            solution: 'AWS Cognito',
          },
          {
            component: 'GCP',
            solution: 'Google Identity Platform',
          },
          {
            component: 'Azure',
            solution: 'Azure Active Directory B2C',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:accountRecovery`,
        icon: AccountRecoveryIcon,
        title: 'Account recovery',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
          {
            component: 'AWS',
            solution: 'AWS Cognito',
          },
          {
            component: 'GCP',
            solution: 'Google Identity Platform',
          },
          {
            component: 'Azure',
            solution: 'Azure Active Directory B2C',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.USERS_AND_ACCOUNTS}:dataAndPrivacySettings`,
        icon: DataAndPrivacyIcon,
        title: 'Data and privacy settings',
        technicalSolution: [
          {
            component: 'Identity Provider Service',
            solution: 'Ory Kratos',
          },
          {
            component: 'AWS',
            solution: 'AWS Cognito',
          },
          {
            component: 'GCP',
            solution: 'Google Identity Platform',
          },
          {
            component: 'Azure',
            solution: 'Azure Active Directory B2C',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.GENERATED_CONTENT,
    title: 'User generated content',
    description: [
      ' User-generated content (UGC) feature allows for users to create their own content and interact with other users content, e.g., to upload and share text, images, videos, posted on online platforms',
    ],
    columns: 3,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.5,
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:uploadAndSharing`,
        icon: MediaFilesIcon,
        title: 'Text, images, videos, and audio upload & sharing',
        technicalSolution: [
          {
            component: 'Storage',
            solution: 'S3-Compatible',
          },
          {
            component: 'AWS',
            solution: 'S3',
          },
          {
            component: 'GCP',
            solution: 'GCS',
          },
          {
            component: 'Azure',
            solution: 'Azure Storage',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:likesAndReposts`,
        icon: LikesIcon,
        title: 'Likes, shares, and reposts',
        technicalSolution: [
          {
            component: 'Graph Database',
            solution: 'dGraph',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:tags`,
        icon: TagsIcon,
        title: 'Tags & categorization',

        estimation: {
          'platform:ios': 32,
          'platform:android': 33,
          'platform:hybrydMobile': 57,
          'platform:web': 32,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:contentEditingAndManagement`,
        icon: ContentEditingIcon,
        title: 'Content editing & management',
        technicalSolution: [
          {
            component: 'CMS',
            solution: 'Strapi',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:reports`,
        icon: ReportsIcon,
        title: 'Reports on inappropriate content',
        technicalSolution: [
          {
            component: 'CMS',
            solution: 'Strapi',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.GENERATED_CONTENT}:privacyAndVisibility`,
        icon: PrivacyIcon,
        title: 'Privacy & visibility levels',
        technicalSolution: [
          {
            component: 'CMS',
            solution: 'Strapi',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 65,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH,
    title: 'Media, files & search',
    description: [
      'This feature allows users to interact with various types of media files, such as images, videos, audio, and documents',
    ],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.3,
      backend: 0.7,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:upload`,
        icon: UploadIcon,
        title: 'Upload images, videos, audio, and documents',
        technicalSolution: [
          {
            component: 'Storage',
            solution: 'S3-Compatible',
          },
          {
            component: 'AWS',
            solution: 'S3',
          },
          {
            component: 'GCP',
            solution: 'GCS',
          },
          {
            component: 'Azure',
            solution: 'Azure Storage',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 52,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:playback`,
        icon: PlaybackIcon,
        title: 'Playback audio, video, photo',

        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:processing`,
        icon: ProcessingIcon,
        title: 'Processing audio, video, photo',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:download`,
        icon: DownloadMediaIcon,
        title: 'Download media files for offline access',

        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:playlists`,
        icon: PlaylistIcon,
        title: 'Playlists or albums of media files',

        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:dataSearch`,
        icon: DataSearchIcon,
        title: 'Data search',
        technicalSolution: [
          {
            component: 'Indexing',
            solution: 'ElasticSearch',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:captions`,
        icon: TagsIcon,
        title: 'Captions, titles, tags, and descriptions',
        technicalSolution: [
          {
            component: 'Indexing',
            solution: 'ElasticSearch',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MEDIA_AND_SEARCH}:mediaFilesEditing`,
        icon: MediaFilesEditingIcon,
        title: 'Media files editing',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING,
    title: 'Calendars, booking & scheduling',
    description: [
      'Make appointment scheduling for end-users hussle-free by adding calendar sync, sending automatic notifications and reminders, work hours setting, canceling and rescheduling, time zones selection, online meetings, etc.',
    ],
    columns: 3,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.7,
      backend: 0.3,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:calendars`,
        icon: CalendarsIcon,
        title: 'Calendar sync',

        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 27,
          'platform:web': 23,
        },
        additionalInfo:
          'Calendar sync allows for integration of calendars with other platforms and tools.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:booking`,
        icon: BookingIcon,
        title: 'Booking page',
        technicalSolution: [
          {
            component: 'Calendar solution',
            solution: 'Сal.com',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'A booking page enables customers to make reservations or appointments online.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:eventListings`,
        icon: EventListingsIcon,
        title: 'Event listings',
        technicalSolution: [
          {
            component: 'Calendar solution',
            solution: 'Сal.com',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Event listings allow viewing and accessing of a list of upcoming events or appointments.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:reservations`,
        icon: ReservationsIcon,
        title: 'Reservations',
        technicalSolution: [
          {
            component: 'Calendar solution',
            solution: 'Сal.com',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Reservations enable the management of bookings or appointments for services or events.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:menuOrdering`,
        icon: MenuOrderingIcon,
        title: 'Menu\\Ordering',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Menu or ordering provides access and view of menus and allows for making orders for food or other items.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.CALENDARS_AND_BOOKING}:ticketing`,
        icon: TicketingIcon,
        title: 'Ticketing',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Allows for collecting requests and tracking ticket lifecycle from creation to resolution.',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS,
    title: 'Diagrams, charts, dashboards & 3D graphics',
    description: [
      'Enhance your experience with data usage and analytics via customizing dashboard panels',
    ],
    columns: 5,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.7,
      backend: 0.3,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS}:taskLists`,
        icon: TaskListsIcon,
        title: 'Task lists',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'This feature allows users to create and manage lists of tasks, projects, or objectives.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS}:customCharts`,
        icon: CustomChartsIcon,
        title: 'Custom charts',
        technicalSolution: [
          {
            component: 'Graph Library',
            solution: 'AntV/D3/ChartJS/HighCharts/amCharts',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo:
          'Custom charts enable the creation and customization of charts and graphs to visualize data.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS}:customDiagrams`,
        icon: CustomDiagramsIcon,
        title: 'Custom diagrams',
        technicalSolution: [
          {
            component: 'Graph Library',
            solution: 'AntV/D3/ChartJS/HighCharts/amCharts',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo:
          'Custom diagrams provide the ability to create and customize diagrams to visualize data or relationships.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS}:customDashboards`,
        icon: DashboardIcon,
        title: 'Custom dashboards',
        technicalSolution: [
          {
            component: 'DashBoards',
            solution: 'Plotly/Streamlit/Google Data Studio/Retool',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo:
          'Custom dashboards allow for the creation and customization of interactive dashboards to visualize and monitor data, metrics, and KPIs.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DIAGRAM_AND_CHARTS}:3Dgraphics`,
        icon: AnimatedGraphicsIcon,
        title: 'Animated 3D computer graphics for a web app',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.LOCATIONS,
    title: 'Locations',
    description: [
      'Provide advanced location-based services for your customers to enrich user experience',
    ],
    columns: 5,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.7,
      backend: 0.3,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.LOCATIONS}:maps`,
        icon: MapsIcon,
        title: 'Maps integration',
        technicalSolution: [
          {
            component: 'Maps provider',
            solution: 'MapBox',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.LOCATIONS}:navigation`,
        icon: NavigationIcon,
        title: 'Navigation',
        technicalSolution: [
          {
            component: 'Routes Provider',
            solution: 'Google Maps Platform',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.LOCATIONS}:geofencing`,
        icon: GeofencingIcon,
        title: 'Geofencing',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Triggering an action via GPS or RFID technology when a mobile device enters a set location.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.LOCATIONS}:markers`,
        icon: MarkersIcon,
        title: 'Markers, advanced location queries',
        technicalSolution: [
          {
            component: 'Routes Provider',
            solution: 'Google Maps Platform',
          },
        ],
        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo: 'Path points, venues, geo areas, path lines etc.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.LOCATIONS}:3rdPartyData`,
        icon: LocationsThirdPartyDataIcon,
        title: 'Using 3rd party location data',
        technicalSolution: [
          {
            component: 'Mapping',
            solution: 'Google Maps Platform/OSM',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: 'E.g. from services like Foursquare, Google Places, etc.',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.SOCIAL_FEATURES_AND_MESSAGING,
    title: 'Social features & messaging',
    description: [
      'Allow brands & users to communicate with each other through direct/private messages, posts, сaptions, сomments, follower lists, groups, etc.',
    ],
    columns: 3,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.5,
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.SOCIAL_FEATURES_AND_MESSAGING}:chat`,
        icon: ChatIcon,
        title: 'In-app messaging/сhat',
        technicalSolution: [
          {
            component: 'Realtime SDK',
            solution: 'SendBird/GetStream/Firebase',
          },
        ],
        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SOCIAL_FEATURES_AND_MESSAGING}:forumsAndComments`,
        icon: ForumsAndCommentsIcon,
        title: 'Forums, comments',
        technicalSolution: [
          {
            component: 'Comments',
            solution: 'Disqus',
          },
        ],
        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SOCIAL_FEATURES_AND_MESSAGING}:socialSharing`,
        icon: SocialSharingIcon,
        title: 'Social sharing',
        technicalSolution: [
          {
            component: 'Social',
            solution: 'Branch.io',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.NOTIFICATIONS,
    title: `Notifications`,
    description: [
      'Notifications help you deliver messages in-app or outside your app to provide users with reminders, info, bonuses, etc.',
    ],
    columns: 2,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.3,
      backend: 0.7,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.NOTIFICATIONS}:pushNotifications`,
        icon: PushNotificationsIcon,
        title: 'Send push notifications',
        technicalSolution: [
          {
            component: 'Push Notifications',
            solution: 'Google Firebase',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo:
          'Push notifications are displayed within the app, as a banner or pop-up. They can be used to alert users of new content, updates, or other relevant information.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.NOTIFICATIONS}:emailAndSMS`,
        icon: EmailAndSMSIcon,
        title: 'Send e-mails, SMS',
        technicalSolution: [
          {
            component: 'SMS Provider',
            solution: 'Twilio',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Email notifications can be used to alert users of new messages, updates, or other important information.SMS notifications can be used to alert users of new messages, updates, or other important information.',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.DATABASE_AND_API,
    title: `Database, 3rd party APIs & data`,
    description: [
      'Store, analyze and use data about your app’s users and add extra functionality to digital products',
    ],
    columns: 3,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0,
      backend: 1,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:text`,
        icon: TextIcon,
        title: 'Store simple data (text)',
        technicalSolution: [
          {
            component: 'Database',
            solution: 'Postgres/MongoDB/MsSQL',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 37,
          'platform:web': 23,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:media`,
        icon: MediaIcon,
        title: 'Store large files (video, audio, etc)',
        technicalSolution: [
          {
            component: 'Storage',
            solution: 'S3-Compatible',
          },
          {
            component: 'AWS',
            solution: 'S3',
          },
          {
            component: 'GCP',
            solution: 'GCS',
          },
          {
            component: 'Azure',
            solution: 'Azure Storage',
          },
        ],
        estimation: {
          'platform:ios': 41,
          'platform:android': 42,
          'platform:hybrydMobile': 51,
          'platform:web': 47,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:3rdPartyAPI`,
        icon: ThirdPartyAPIIcon,
        title: `Integration of 3rd party APIs's`,

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:sync`,
        icon: SyncIcon,
        title: 'Real time synchronization between devices',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:API`,
        icon: APIIcon,
        title: 'App should have an API to integrate with other apps',
        technicalSolution: [
          {
            component: 'API',
            solution: 'OpenAPI, gRPC, GraphQL',
          },
        ],
        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.DATABASE_AND_API}:3rdPartyData`,
        icon: ThirdPartyDataIcon,
        title: 'Integration of 3rd party data sources',
        technicalSolution: [
          {
            component: 'Workflow Engine',
            solution: 'Argo Workflows',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.MONETIZATION,
    title: 'Monetization, advertisements & payment processing',
    description: [
      'Make money through your apps by implementing affiliate marketing features, subscriptions, fees, freemium model, payment gateways',
    ],
    columns: 5,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.5,
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.MONETIZATION}:purchases`,
        icon: PurchasesIcon,
        title: 'In app purchases',

        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'In-app purchases allow users to buy virtual items within the app, such as extra lives in a game, premium filters in a photo editing app, etc.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MONETIZATION}:subscriptionPlans`,
        icon: SubscriptionPlansIcon,
        title: 'Subscription plans',
        technicalSolution: [
          {
            component: 'InApp subscription processing',
            solution: 'RevenueCat',
          },
          {
            component: 'Web subscription processing',
            solution: 'Recurly',
          },
        ],
        estimation: {
          'platform:ios': 59,
          'platform:android': 58,
          'platform:hybrydMobile': 65,
          'platform:web': 58,
        },
        additionalInfo:
          'Subscription plan allows users to pay for access to a service or content on a recurring basis, such as monthly or annually.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MONETIZATION}:paymentProcessing`,
        icon: PaymentProcessingIcon,
        title: 'Payment processing',
        technicalSolution: [
          {
            component: 'Payment provider',
            solution: 'Stripe/Braintree',
          },
        ],
        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo:
          'Payment gateway integration for online payments processing, authenticating and passing user data, e.g., Stripe, Braintree, PayPal.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MONETIZATION}:commissions`,
        icon: CommissionsIcon,
        title: 'Commissions in affiliate marketing programs',

        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo:
          'This feature allows for earning a commission for promoting other products or services within the app.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.MONETIZATION}:revenueSplit`,
        icon: PayoutsWithCommisionsIcon,
        title: 'Revenue split',
        technicalSolution: [
          {
            component: 'Revenue Split',
            solution: 'Stripe Connect',
          },
        ],
        estimation: {
          'platform:ios': 120,
          'platform:android': 130,
          'platform:hybrydMobile': 140,
          'platform:web': 135,
        },
        additionalInfo: '',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.ADMIN_PANEL,
    title: 'Admin panel, CMS, reporting & analytics',
    description: [
      'Effectively manage your digital product configurations, settings, content, features and users to make data-driven decisions',
    ],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.5,
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:cmsIntegration`,
        icon: CMSIntegrationIcon,
        title: 'CMS integration',
        technicalSolution: [
          {
            component: 'Integration',
            solution: 'Strapi/Contentful/HubSpot',
          },
        ],
        estimation: {
          'platform:ios': 61,
          'platform:android': 62,
          'platform:hybrydMobile': 65,
          'platform:web': 64,
        },
        additionalInfo:
          'Integrating with a Content Management System (CMS) helps simplify content creation and management processes.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:crmIntegration`,
        icon: CRMIcon,
        title: 'CRM integration',
        technicalSolution: [
          {
            component: 'Integration',
            solution: 'Sales force/Pipedrive/Kommo',
          },
        ],
        estimation: {
          'platform:ios': 61,
          'platform:android': 62,
          'platform:hybrydMobile': 65,
          'platform:web': 64,
        },
        additionalInfo: '',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:customCMS`,
        icon: CustomCMSIcon,
        title: 'Custom CMS\\CMS customization',

        estimation: {
          'platform:ios': 120,
          'platform:android': 130,
          'platform:hybrydMobile': 140,
          'platform:web': 135,
        },
        additionalInfo:
          'Here we can  help with creating a custom CMS, customizing an existing CMS interface, adding or modifying features, or integrating with other systems.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:userManagement`,
        icon: UsersManagementIcon,
        title: 'User management',

        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo:
          'This feature allows app administrators to manage and organize the app users, such as creating or deleting user accounts, modifying user information, assigning user roles, etc.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:contentModeration`,
        icon: ContentModerationIcon,
        title: 'Content moderation',

        estimation: {
          'platform:ios': 80,
          'platform:android': 82,
          'platform:hybrydMobile': 99,
          'platform:web': 87,
        },
        additionalInfo:
          'This feature allows app administrators to review and approve or reject user-generated content before it is displayed within the app.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:analytics`,
        icon: AnalyticsIcon,
        title: 'Usage analytics',
        technicalSolution: [
          {
            component: 'Analytics',
            solution: 'PostHog/MixPanel/Google Analytics/Amplitude',
          },
        ],
        estimation: {
          'platform:ios': 22,
          'platform:android': 24,
          'platform:hybrydMobile': 23,
          'platform:web': 23,
        },
        additionalInfo:
          'Usage analytics allows for tracking and analyzing user behavior,  engagement and usage patterns within the app.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.ADMIN_PANEL}:reporting`,
        icon: ReportingIcon,
        title: 'Reporting',
        technicalSolution: [
          {
            component: 'Reporting',
            solution: 'Google Data Studio, Google Spreadsheets',
          },
        ],
        estimation: {
          'platform:ios': 120,
          'platform:android': 130,
          'platform:hybrydMobile': 140,
          'platform:web': 135,
        },
        additionalInfo:
          'This feature allows for generating reports and data visualizations based on user behavior, usage patterns, revenue, etc.',
        description: '',
      },
    ],
  },
  /// new part
  {
    value: MVP_CALCULATOR_STEPS.LOCALIZATION,
    title: 'Localization',
    description: [
      'Attract customers worldwide by translating and adapting your app into multiple languages and regions',
    ],
    columns: 2,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.5, // Проверить
      backend: 0.5,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.LOCALIZATION}:RTLSupport`,
        icon: rtlSupportIcon,
        title: 'RTL support',

        estimation: {
          'platform:ios': 32,
          'platform:android': 31,
          'platform:hybrydMobile': 30,
          'platform:web': 32,
        },
        additionalInfo:
          'RTL (Right-to-Left) support allows the app to display text and content in a right-to-left format, which is commonly used in Arabic, Hebrew, and other languages.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.LOCALIZATION}:multipleLanguages`,
        icon: MultipleLanguagesIcon,
        title: 'Multiple languages support',
        technicalSolution: [
          {
            component: 'Frontend I18n',
            solution: 'React I18n',
          },
        ],
        estimation: {
          'platform:ios': 61,
          'platform:android': 62,
          'platform:hybrydMobile': 65,
          'platform:web': 64,
        },
        additionalInfo:
          'This feature includes translations, local currency support, and language switching functionality, where users can select their preferred language within the app.',
        description: '',
      },
    ],
  },

  {
    value: MVP_CALCULATOR_STEPS.SECURITY,
    title: 'Security & compliance',
    description: [
      'Ensure your product security & compliance with various security and data protection regulations such as HIPAA, PCI DSS, and GDPR',
    ],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.1,
      backend: 0.9,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.SECURITY}:SSLCertificates`,
        icon: SSLCertificatesIcon,
        title: 'SSL certificates',
        technicalSolution: [
          {
            component: 'SSL Certificates',
            solution: 'Let’s Encrypt',
          },
        ],
        estimation: {
          'platform:ios': 32,
          'platform:android': 31,
          'platform:hybrydMobile': 30,
          'platform:web': 32,
        },
        additionalInfo:
          'The app can use secure protocols such as HTTPS or SSL to encrypt the communication between the app and the server.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SECURITY}:twoFactorAuthentication`,
        icon: KeyIcon,
        title: 'Two-factor authentication',

        estimation: {
          'platform:ios': 32,
          'platform:android': 31,
          'platform:hybrydMobile': 30,
          'platform:web': 32,
        },
        additionalInfo:
          'Two-factor authentication adds an extra layer of security to login by requiring users to provide both a password and a unique code from a secondary device, SMS or something like that.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SECURITY}:encryption`,
        icon: EncryptionIcon,
        title: 'Encryption',

        estimation: {
          'platform:ios': 120,
          'platform:android': 130,
          'platform:hybrydMobile': 140,
          'platform:web': 135,
        },
        additionalInfo:
          'Sensitive data, such as user passwords and financial information, can be encrypted to prevent unauthorized access or data breaches.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.SECURITY}:compliance`,
        icon: ComplianceIcon,
        title: 'Compliance',

        estimation: {
          'platform:ios': 160,
          'platform:android': 161,
          'platform:hybrydMobile': 160,
          'platform:web': 166,
        },
        additionalInfo:
          'The app can be built to comply with various security and data protection regulations such as HIPAA, PCI DSS, and GDPR.',
        description: '',
      },
    ],
  },

  {
    value: MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES,
    title: 'AI technologies',
    description: [
      'Unlock the potential of artificial intelligence & machine learning in implementing advanced natural language processing capabilities, computer vision, AI chatbots, predictive analytics, etc.',
    ],
    columns: 5,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.3,
      backend: 0.7,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:imageGeneration`,
        icon: ImageGenerationIcon,
        title: 'Image generation',
        technicalSolution: [
          {
            component: 'Image generation',
            solution: 'OpenAI',
          },
        ],
        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo:
          'Image generation involves using AI algorithms to generate new images based on a given set of input data, such as textual descriptions or other images.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:TextGeneration`,
        icon: TextGenerationIcon,
        title: 'Text generation',
        technicalSolution: [
          {
            component: 'Text generation',
            solution: 'OpenAI',
          },
        ],
        estimation: {
          'platform:ios': 51,
          'platform:android': 52,
          'platform:hybrydMobile': 62,
          'platform:web': 52,
        },
        additionalInfo:
          'Text generation uses AI to generate written content, such as news articles, product descriptions, or poetry.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:naturalLanguageQuery`,
        icon: NaturalLanguageQueryIcon,
        title: 'Natural language query',
        technicalSolution: [
          {
            component: 'NLP Library',
            solution: 'Spacy',
          },
        ],
        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo:
          'Natural language query allows users to interact with a system using natural language, such as asking questions in a conversational manner.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:textClassification`,
        icon: TextClassificationIcon,
        title: 'Text classification',
        technicalSolution: [
          {
            component: 'NLP Library',
            solution: 'Spacy',
          },
        ],
        estimation: {
          'platform:ios': 60,
          'platform:android': 60,
          'platform:hybrydMobile': 60,
          'platform:web': 60,
        },
        additionalInfo:
          'Text classification uses AI to categorize text into various predefined categories or classes based on its content.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:languageTranslation`,
        icon: MultipleLanguagesSupportIcon,
        title: 'Language translation',
        technicalSolution: [
          {
            component: 'NLP Library',
            solution: 'Spacy',
          },
        ],
        estimation: {
          'platform:ios': 30,
          'platform:android': 30,
          'platform:hybrydMobile': 30,
          'platform:web': 30,
        },
        additionalInfo:
          'Language translation enables the automatic conversion of text from one language to another.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:imageAndVideoRecognition`,
        icon: ImageRecognitionIcon,
        title: 'Image & video recognition and analysis',
        technicalSolution: [
          {
            component: 'Image Recognition',
            solution: 'PyTorch/TensorFlow',
          },
        ],
        estimation: {
          'platform:ios': 93,
          'platform:android': 92,
          'platform:hybrydMobile': 102,
          'platform:web': 100,
        },
        additionalInfo:
          'Image and video recognition and analysis involves using AI to identify objects, people, and other elements within images and videos.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:imageClassification`,
        icon: ImageClassificationIcon,
        title: 'Image classification',
        technicalSolution: [
          {
            component: 'Image Recognition',
            solution: 'PyTorch/TensorFlow',
          },
        ],
        estimation: {
          'platform:ios': 61,
          'platform:android': 62,
          'platform:hybrydMobile': 76,
          'platform:web': 66,
        },
        additionalInfo:
          'Defining a set of target classes and training a model to recognize them using labeled example photos.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:OCR`,
        icon: OCRIcon,
        title: 'OCR',
        technicalSolution: [
          {
            component: 'Image Recognition',
            solution: 'Tesseract/docTR',
          },
        ],
        estimation: {
          'platform:ios': 74,
          'platform:android': 73,
          'platform:hybrydMobile': 89,
          'platform:web': 99,
        },
        additionalInfo:
          'Optical character recognition converts an image of text into a machine-readable text format.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:predictiveAnalytics`,
        icon: PredictiveAnalyticsIcon,
        title: 'Predictive analytics',

        estimation: {
          'platform:ios': 35,
          'platform:android': 39,
          'platform:hybrydMobile': 45,
          'platform:web': 37,
        },
        additionalInfo:
          'Predictive analytics uses AI to analyze historical data and make predictions about future events or outcomes.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:realTimeDataProcessing`,
        icon: DataProcessingIcon,
        title: 'Real-time data processing',

        estimation: {
          'platform:ios': 43,
          'platform:android': 43,
          'platform:hybrydMobile': 43,
          'platform:web': 43,
        },
        additionalInfo:
          'Real-time data processing enables AI systems to process and respond to incoming data in real-time, allowing for real-time decision making and analysis.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:facialRecognition`,
        icon: FacialRecognitionIcon,
        title: 'Facial recognition',
        technicalSolution: [
          {
            component: 'Facial Recognition',
            solution: 'FaceNet',
          },
        ],
        estimation: {
          'platform:ios': 60,
          'platform:android': 62,
          'platform:hybrydMobile': 66,
          'platform:web': 69,
        },
        additionalInfo:
          'Facial recognition involves using AI algorithms to identify specific individuals based on their facial features.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:virtualRealityIntegration`,
        icon: ARIcon,
        title: 'Virtual and augmented reality integration',

        estimation: {
          'platform:ios': 240,
          'platform:android': 260,
          'platform:hybrydMobile': 280,
          'platform:web': 270,
        },
        additionalInfo:
          'VR and AR integration uses AI to enhance virtual and augmented reality experiences, such as through more realistic or interactive environments.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES}:other`,
        icon: OtherIcon,
        title: 'Other',

        estimation: {
          'platform:ios': 160,
          'platform:android': 160,
          'platform:hybrydMobile': 160,
          'platform:web': 160,
        },
        additionalInfo:
          'Other AI technologies may include speech recognition, generative adversarial networks (GANs), reinforcement learning, and more.',
        description: '',
      },
    ],
  },
  {
    value: MVP_CALCULATOR_STEPS.BLOCKCHAIN,
    title: 'Blockchain & NFT',
    description: [
      'Leverage blockchain & NFT technologies to shape forward-thinking business with enhanced security, better traceability and scalability and lower costs',
    ],
    columns: 4,
    isMultiSelect: true,
    canBeSkipped: true,
    rateOfTeam: {
      frontend: 0.4,
      backend: 0.6,
      designer: 0,
    },
    items: [
      {
        value: `${MVP_CALCULATOR_STEPS.BLOCKCHAIN}:cryptoWallet`,
        icon: CryptoWalletIcon,
        title: 'Crypto wallet',
        technicalSolution: [
          {
            component: 'Digital Custody Management',
            solution: 'Fireblocks',
          },
        ],
        estimation: {
          'platform:ios': 160,
          'platform:android': 161,
          'platform:hybrydMobile': 160,
          'platform:web': 166,
        },
        additionalInfo:
          'A crypto wallet is a digital wallet that can store and manage cryptocurrencies.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BLOCKCHAIN}:supportMultipleNetworks`,
        icon: NFTIcon,
        title: 'Support for multiple networks',

        estimation: {
          'platform:ios': 120,
          'platform:android': 130,
          'platform:hybrydMobile': 140,
          'platform:web': 135,
        },
        additionalInfo:
          'This means that the app or platform can handle and manage  smart contracts in different networks.',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BLOCKCHAIN}:DeFiAppImplementation`,
        icon: DeFiIcon,
        title: 'DeFi app implementation',
        technicalSolution: [
          {
            component: 'Protocols',
            solution: 'Solana, NEAR, Tron, Ethereum',
          },
        ],
        estimation: {
          'platform:ios': 160,
          'platform:android': 161,
          'platform:hybrydMobile': 160,
          'platform:web': 166,
        },
        additionalInfo:
          'Allows for trading & storing digital assets on blockchain networks, tracking transaction history, exchange cryptocurrencies, etc. ',
        description: '',
      },
      {
        value: `${MVP_CALCULATOR_STEPS.BLOCKCHAIN}:NFTImplementation`,
        icon: MultipleNetworksIcon,
        title: 'NFT implementation',

        estimation: {
          'platform:ios': 160,
          'platform:android': 161,
          'platform:hybrydMobile': 160,
          'platform:web': 166,
        },
        additionalInfo:
          'Allows for creating, buying, selling, minting, and managing NFTs, setting royalties, support for NFT standards, etc.',
        description: '',
      },
    ],
  },
];

export default MVPCalculatorStepsData;
